import React from 'react';

class Bargaining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // Bindings
  }

  componentDidMount() {}

  render() {
    const { players } = this.props.group;
    const energetics = players.find((p) => p.rindex === 1);
    const genrex = players.find((p) => p.rindex === 0);

    const energeticsMin = Math.min(
      energetics.aspiration,
      energetics.reservation
    );
    const energeticsWidth =
      (Math.abs(energetics.reservation - energetics.aspiration) / 500) * 100;
    const energeticsLeft = Math.abs(energeticsMin / 500) * 100;

    const genrexMin = Math.min(genrex.aspiration, genrex.reservation);
    const genrexWidth =
      (Math.abs(genrex.reservation - genrex.aspiration) / 500) * 100;
    const genrexLeft = Math.abs(genrexMin / 500) * 100;

    const energeticsBlock = (
      <span
        className='badge badge-primary'
        style={{
          position: 'absolute',
          lineHeight: '40px',
          fontSize: '16px',
          top: '-22px',
          left: energeticsLeft + '%',
          width: energeticsWidth + '%',
          backgroundColor: 'rgba(0, 123, 255, .7)',
        }}>
        <span
          style={{
            position: 'absolute',
            top: '-34px',
            left: '-25px',
            color: '#007bff',
          }}>
          ${Math.min(energetics.aspiration, energetics.reservation)}
        </span>
        <span
          style={{
            position: 'absolute',
            top: '-34px',
            right: '-25px',
            color: '#007bff',
          }}>
          ${Math.max(energetics.aspiration, energetics.reservation)}
        </span>
        &nbsp;
      </span>
    );

    const genrexsBlock = (
      <span
        className='badge'
        style={{
          position: 'absolute',
          lineHeight: '40px',
          fontSize: '16px',
          top: '-22px',
          left: genrexLeft + '%',
          width: genrexWidth + '%',
          backgroundColor: 'rgba(97, 46, 160, .7)',
        }}>
        <span
          style={{
            position: 'absolute',
            bottom: '-34px',
            left: '-25px',
            color: 'rgba(97, 46, 160)',
          }}>
          ${Math.min(genrex.aspiration, genrex.reservation)}
        </span>
        <span
          style={{
            position: 'absolute',
            bottom: '-34px',
            right: '-25px',
            color: 'rgba(97, 46, 160)',
          }}>
          ${Math.max(genrex.aspiration, genrex.reservation)}
        </span>
        &nbsp;
      </span>
    );

    return (
      <div className='text-center mt-5 ml-3 mr-3'>
        <div
          style={{ position: 'relative', padding: '2px', background: '#333' }}>
          {energeticsBlock}
          {genrexsBlock}
        </div>

        <div className='mt-5 pt-5 text-center'>
          <span
            className='badge'
            style={{
              height: '20px',
              width: '20px',
              lineHeight: '18px',
              borderRadius: '50%',
              backgroundColor: 'rgb(97, 46, 160)',
            }}>
            &nbsp;
          </span>{' '}
          Genrex
          <span
            className='badge badge-primary ml-4'
            style={{
              height: '20px',
              width: '20px',
              lineHeight: '18px',
              borderRadius: '50%',
            }}>
            &nbsp;
          </span>{' '}
          Energetics
        </div>
      </div>
    );
  }
}
export default Bargaining;
