import React from 'react';
import numeral from 'numeral';

// The Header creates links that can be used to navigate
// between routes.
class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { items, players } = this.props.group;
    const accepted = items.find((i) => i.status === 'accepted');
    const energetics = players.find((p) => p.rindex === 1);
    const batna = energetics.rsub === 0 ? 'Low ($170M)' : 'High ($225M)';
    const genrex = players.find((p) => p.rindex === 0);

    return (
      <div>
        <div className='row mt-3'>
          <div className='col-6 text-center'>
            <p className='mb-1'>Final Offer</p>
            <h3>
              {accepted ? numeral(accepted.amount).format('$0,0') + 'M' : '-'}
            </h3>
          </div>
          <div className='col-6 text-center'>
            <p className='mb-1'>Energetics BATNA</p>
            <h3>{batna}</h3>
          </div>
          <div className='col-6 text-center mt-4'>
            <p className='mb-1'>
              Energetics
              <br />
              Satisfaction
            </p>
            <h3>{energetics.satisfaction || 'Pending'}</h3>
          </div>
          <div className='col-6 text-center mt-4'>
            <p className='mb-1'>
              Generex
              <br />
              Satisfaction
            </p>
            <h3>{genrex.satisfaction || 'Pending'}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Summary;
