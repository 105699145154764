import React from 'react'
import { connect } from 'react-redux'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import GroupDebrief from './GroupDebrief';

class DancefloorChart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            group: 0,
            group_modal: null
        }
        // Bindings 
        this.setGroup           = this.setGroup.bind(this)
        this.setGroupModal      = this.setGroupModal.bind(this)
        this.handleGroupChange  = this.handleGroupChange.bind(this)
    }

    componentDidMount() {}

    setGroup(v){
        const { dances } = this.props
        let value = v
        if( v < 0 )
            value = dances.length
        if( v > dances.length )
            value = 0
        this.setState({ group: value })
    }

    setGroupModal( gindex ){
        const { game } = this.props
        const group_modal = game.data ? game.data.groups[gindex] : null
        this.setState({ group_modal })
    }

    handleGroupChange(e){
        const value = e.target.value
        if( value.length > 0 )
            this.setState({ group: parseInt(value) })
    }

    render() {

        const { group } = this.state
        const { dances, minRange, popup } = this.props

        // const series = dances[group-1] ? [dances[group-1]] : dances
        const series = group > 0 ? dances.map( (d, di) => {
            d.visible = di === group-1 ? true : false
            return d;
        }) : dances.map( (d, di) => {
            d.visible = true
            return d;
        })

        const self = this

        return <div style={{ height: '100%' }}>

            { dances.length > 1 && <div style={{ position: 'absolute', right: '15px', width: '100%' }}>
                <div className="col-2 float-right" style={{ position: 'relative', bottom: '-5px', zIndex: 99 }}>

                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <button className="btn btn-primary" onClick={ () => this.setGroup( group-1 ) }>Prev</button>
                        </div>
                        <input type="text" className="form-control text-center" value={ group === 0 ? 'All' : group } placeholder="#" />
                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={ () => this.setGroup( group+1 ) }>Next</button>
                        </div>
                    </div>

                </div>
            </div> }

            <HighchartsReact
                containerProps={{ style: { height: "100%" } }}
                highcharts={ Highcharts }
                options={{ 
                    title: { text: 'Offer Evolution' },
                    chart: { height: '450px' },
                    credits: { enabled: false },
                    legend: { layout: 'vertical', align: 'right', verticalAlign: 'top', enabled: false },
                    xAxis: {
                        allowDecimals: false,
                        min: 1,
                        minRange: minRange || null
                        // categories: ['Issue by Issue', 'Package Offer', 'MESOs', 'Finished Negotiation']
                    },
                    yAxis: {
                        title: { text: '$M' },
                        allowDecimals: false,
                        min: 0,
                        minRange: 500
                    },
                    tooltip: {
                        formatter: function () {
                            const p = this.point
                            return `<b>${this.series.name}</b><br/><b>Offer: $${p.y}M</b><br/>Sent By: ${p.author}<br/>Negotiation Status: ${p.status}<br/>BATNA: ${p.batna}`
                        }
                    },
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            events: {
                                click: function(event) { 
                                    if( popup === true )
                                        self.setGroupModal(event.point.pair_index)
                                }
                            }
                        }
                    },
                    series: series
                }}
                />

            <Modal isOpen={ this.state.group_modal != null ? true : false } 
                centered={true} 
                size="xxl"
                toggle={ () => this.setState({ group_modal: null }) }>
                <ModalBody className="text-center">
                    <FontAwesomeIcon 
                        icon={ faTimesCircle } 
                        size="2x"
                        className="icon bg-white text-danger"
                        style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', borderRadius: '50%' }}
                        onClick={() => this.setState({ group_modal: null })} />
                    { this.state.group_modal && <GroupDebrief group={ this.state.group_modal } /> }
                </ModalBody>
            </Modal>

        </div>
    }

}

const mapStateToProps = state => {
    const { game } = state
    return {
        game
    }
}

export default connect(mapStateToProps)(DancefloorChart)