import React from 'react'
import { connect } from 'react-redux'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

// Actions
// import { gameActions } from '../../actions';

import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header'
import { DebriefMenu } from './debrief_menu'
import GroupDebrief from '../../components/GroupDebrief';

class DebriefBatna extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            group: null
        }
        // Bindings
        this.setGroup = this.setGroup.bind(this)
    }

    setGroup( gid ){
        const { game } = this.props
        const group = game.data.groups.find( g => g._id === gid )
        this.setState({ group })
    }

    render() {

        const { game } = this.props

        if( !game.data )
            return null

        const deals = game.data.groups.filter( dyad => dyad.status === 'deal' )
        const batna0 = deals.filter( dyad => {
                const energetics = dyad.players.find( p => p.rindex === 1 )
                return energetics && energetics.rsub === 0
            })
            .map( dyad => {
                const accepted = dyad.items.find( o => o.status === 'accepted' )
                return { x: accepted.amount, y: 0, _id: dyad._id }
            })
        const batna1 = deals.filter( dyad => {
            const energetics = dyad.players.find( p => p.rindex === 1 )
            return energetics && energetics.rsub === 1
        })
        .map( dyad => {
            const accepted = dyad.items.find( o => o.status === 'accepted' )
            return { x: accepted.amount, y: 0, _id: dyad._id }
        })

        const self = this;

        return <div>

            <Header />

            <h2 className="mt-3 sec-title">
                BATNA
                <small className="float-right">
                    <DebriefMenu game_id={game.data._id} location={this.props.location} />
                </small>
            </h2>

            <div className="mt-3">

                <HighchartsReact
                    highcharts={ Highcharts }
                    options={{ 
                        title: { text: 'Final Price ($M)' },
                        chart: { type: 'scatter', height: '500px' },
                        credits: { enabled: false },
                        // legend: { layout: 'vertical', align: 'right', verticalAlign: 'top', enabled: true },
                        xAxis: {
                            // categories: ['Issue by Issue', 'Package Offer', 'MESOs', 'Finished Negotiation']
                            allowDecimals: false,
                            min: 0,
                            max: 600
                        },
                        yAxis: {
                            title: { text: '' },
                            labels: false,
                            gridLineColor: '#FFF',
                            allowDecimals: false
                        },
                        tooltip: {
                            formatter: function () {
                                const p = this.point
                                return `<b>${this.series.name}</b><br/>Final Price: $${p.x}M`
                            }
                        },
                        plotOptions: {
                            series: {
                                cursor: 'pointer',
                                events: {
                                    click: function(event) { 
                                        console.log(event.point) 
                                        self.setGroup(event.point._id)
                                    }
                                }
                            }
                        },
                        series: [
                            {
                                name: 'Energetics Low BATNA',
                                color: 'rgba(74, 144, 226, .5)',
                                marker: {
                                    symbol: 'circle',
                                    radius: 30
                                },
                                shape: 'circle',
                                data: batna0
                            },
                            {
                                name: 'Energetics High BATNA',
                                color: 'rgba(078, 042, 132, .5)',
                                marker: {
                                    symbol: 'circle',
                                    radius: 30
                                },
                                data: batna1
                            }
                        ]
                    }}
                    />
            </div>


            <Modal isOpen={ this.state.group ? true : false } 
                centered={true} 
                size="xxl"
                toggle={ () => this.setState({ group: null }) }>
                <ModalBody className="text-center">
                    <FontAwesomeIcon 
                        icon={ faTimesCircle } 
                        size="2x"
                        className="icon bg-white text-danger"
                        style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', borderRadius: '50%' }}
                        onClick={() => this.setState({ group: null })} />
                        { this.state.group && <GroupDebrief group={ this.state.group } /> }
                </ModalBody>
            </Modal>


        </div>
    }

}

const mapStateToProps = state => {
    const { game } = state
    return {
        game
    }
}

export default connect(mapStateToProps)(DebriefBatna)