import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
// import numeral from 'numeral';

import NumbericInput from './NumericInput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

class BuilderSingle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: typeof this.props.id != 'undefined' ? this.props.id : null,
      author: this.props.author,
      editable:
        typeof this.props.editable != 'undefined' ? this.props.editable : true,
      acceptable:
        typeof this.props.acceptable != 'undefined'
          ? this.props.acceptable
          : false,
      status: this.props.status || '',
      amount: this.props.amount ? this.props.amount : null,
      accept_modal: false,
    };

    // Bindings
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.clearBuilderSingle = this.clearBuilderSingle.bind(this);
  }

  componentDidUpdate(prevProps) {
    let edit = false;
    let newState = {};

    if (this.props.editable !== prevProps.editable) {
      newState['editable'] = this.props.editable;
      edit = true;
    }

    if (this.props.acceptable !== prevProps.acceptable) {
      newState['acceptable'] = this.props.acceptable;
      edit = true;
    }

    if (this.props.status !== prevProps.status) {
      newState['status'] = this.props.status;
      edit = true;
    }

    if (this.props.amount !== prevProps.amount) {
      newState['amount'] = this.props.amount;
      edit = true;
    }

    if (edit) this.setState(newState);
  }

  handleInputChange(value) {
    this.setState({ amount: value }, () => {
      if (this.props.onChange) this.props.onChange(parseInt(this.state.amount));
    });
  }

  onSubmit() {
    const { amount } = this.state;
    if (this.props.onSubmit) this.props.onSubmit({ amount });
  }

  onAccept() {
    this.toggleModal(false);
    if (this.props.onAccept) this.props.onAccept();
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  toggleModal(mode) {
    this.setState({ accept_modal: mode });
  }

  clearBuilderSingle() {
    this.setState({ amount: '' });
  }

  render() {
    const { player } = this.props;
    const roles = ['Generex', 'Energetics'];

    const mine = player._id === this.state.author ? true : false;
    // const roleIndex = typeof this.props.authorRole !== 'undefined' ? this.props.authorRole : 0;
    const sentBy = mine
      ? roles[player.rindex]
      : roles[player.counterpart.rindex];

    let roleBox = mine ? 'bg-kellogg' : 'bg-white';

    const viewMode = (
      <div className='row'>
        <div className='col-12 col-md-4x text-kellogg text-center text-md-leftx'>
          <small>
            Offer #{this.props.index} 
            {this.state.editable && (
              <b
                style={{ fontSize: '10px', padding: '0 3px' }}
                className='d-inline-block ml-2 btn btn-warning'
                onClick={this.clearBuilderSingle}>
                Clear
              </b>
            )}
          </small>
        </div>
        <div className='col-12 col-md-4x'>
          <div className='issues-box text-center'>
            <div className=''>
              <div className='clearfix mb-1'>
                <p className='lead mb-0 clearfix'>
                  <span className='text-kellogg' style={{ fontSize: '1.3rem' }}>
                    <b className='font-bold'>Price</b>: ${this.state.amount}M
                    USD
                  </span>
                </p>
              </div>

              {!mine &&
                !this.state.editable &&
                this.state.status === 'init' &&
                this.state.acceptable && (
                  <div className='mt-4'>
                    <span
                      className='btn btn-outline-kellogg mb-1'
                      onClick={() => this.toggleModal(true)}>
                      Accept Offer
                    </span>
                  </div>
                )}

              {!this.state.editable &&
                !this.state.acceptable &&
                this.state.status === 'init' && (
                  <div className='mt-1 mb-1'>
                    <span className='badge badge-danger'>Rejected</span>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className='col-12 col-md-4x text-kellogg text-center text-md-rightx'>
          <small className='float-rightx'>
            Sent By: {sentBy}
            {this.state.editable && (
              <FontAwesomeIcon
                icon={faTimesCircle}
                size='2x'
                className='icon bg-white text-danger'
                style={{
                  position: 'absolute',
                  top: '-12px',
                  right: '-7px',
                  cursor: 'pointer',
                  borderRadius: '50%',
                }}
                onClick={this.onCancel}
              />
            )}
          </small>
        </div>
      </div>
    );

    const enterMode = (
      <div>
        <div className='clearfix'>
          <small className='float-left'>
            Offer #{this.props.index} 
            {this.state.editable && (
              <b
                style={{ fontSize: '10px', padding: '0 3px' }}
                className='d-inline-block ml-2 btn btn-warning'
                onClick={this.clearBuilderSingle}>
                Clear
              </b>
            )}
          </small>

          <small className='float-right'>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size='2x'
              className='icon bg-white text-danger'
              style={{
                position: 'absolute',
                top: '-12px',
                right: '-7px',
                cursor: 'pointer',
                borderRadius: '50%',
              }}
              onClick={this.onCancel}
            />
          </small>
        </div>
        <div className='mt-2'>
          <div className='issues-box text-center'>
            <div className=''>
              <div className='mt-0'>
                <NumbericInput
                  autoFocus
                  value={this.state.amount}
                  className='form-control form-control-lg text-center'
                  onChange={this.handleInputChange}
                  placeholder='Enter your offer in $M USD'
                />
              </div>

              {!mine &&
                !this.state.editable &&
                this.state.status === 'init' &&
                this.state.acceptable && (
                  <div className='mt-4'>
                    <span
                      className='btn btn-outline-kellogg mb-1'
                      onClick={() => this.toggleModal(true)}>
                      Accept Offer
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className={'card offer-box ' + roleBox}>
        <div style={{ padding: '10px 15px' }}>
          {this.state.editable && enterMode}
          {!this.state.editable && viewMode}

          <Modal isOpen={this.state.accept_modal} centered={true}>
            <ModalBody>Confirm that you want to accept this offer.</ModalBody>
            <ModalFooter>
              <Button color='outline-kellogg' onClick={this.onAccept}>
                Accept
              </Button>
              <Button color='secondary' onClick={() => this.toggleModal(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // const { player } = state;
  // return { player };
  return {};
}

export default connect(mapStateToProps)(BuilderSingle);
