import React from 'react';
import { connect } from 'react-redux';
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'

import DancefloorChart from './DancefloorChart';
import Summary from './Summary';
import Bargaining from './Bargaining';
import ChatHistory from './ChatHistory';

// The Header creates links that can be used to navigate
// between routes.
class StudentDebrief extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 1,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    let data = {};
    data[n] = v;
    this.setState(data);
  }

  render() {
    const { group } = this.props;
    const maxOffers = group.items.length;
    const selectedOffer = group.items[this.state.order - 1];

    const player1 = group.players[0];
    const player2 = group.players[1];

    const energetics = group.players.find((p) => p.rindex === 1);
    const generex = group.players.find((p) => p.rindex === 0);

    let dyads = [group];

    const dances =
      dyads.length > 0
        ? dyads.map((dyad, di) => {
            const energetics = dyad.players.find((p) => p.rindex === 1);
            const dance = dyad.items.map((i, ii) => {
              return {
                x: ii + 1,
                y: i.amount,
                status: dyad.status,
                author: i.author_role === 1 ? 'Energetics' : 'Generex',
                batna: energetics.rsub === 0 ? 'Low ($170M)' : 'High ($225M)',
                marker: {
                  radius: 8,
                  fillColor: i.author_role === 0 ? 'rgb(97, 46, 160)' : 'blue',
                },
              };
            });

            return {
              name: `${dyad.players[0].email} vs ${dyad.players[1].email}`,
              color: 'rgba(53, 119, 242)',
              data: dance,
            };
          })
        : [];

    return (
      <div>
        <div className='row'>
          <div className='col-12 text-center mt-3 mb-4'>
            <p className='lead mb-0'>
              Pair Feedback: {player1.email} &amp; {player2.email}
              {maxOffers === 0 && (
                <h5 className='mb-0 mt-2 text-center text-danger'>
                  No offers were exchanged
                </h5>
              )}
            </p>
          </div>
          {maxOffers > 0 && (
            <div className='col-12 col-md-4 mb-4'>
              <div className='card'>
                <div className='card-header'>Offer Evolution Over Time</div>
                <div
                  className='card-body'
                  style={{ height: '83vh', overflow: 'auto' }}>
                  <DancefloorChart dances={dances} />
                </div>
              </div>
            </div>
          )}
          {maxOffers > 0 && (
            <div className='col-12 col-md-4 mb-4'>
              <div className='card'>
                <div className='card-header'>Offer Details</div>
                <div className='card-body'>
                  <Summary group={group} />
                </div>
              </div>
              <div className='card mt-3'>
                <div className='card-header'>Additional Terms</div>
                <div className='card-body'>
                  <p>
                    <b>
                      Energetics <small>({energetics.email})</small>
                    </b>
                    : {energetics.terms}
                  </p>
                  <p>
                    <b>
                      Generex <small>({generex.email})</small>
                    </b>
                    : {generex.terms}
                  </p>
                </div>
              </div>
              <div className='card mt-3'>
                <div className='card-header'>Bargaining Zone ($M)</div>
                <div className='card-body pt-5 pb-5'>
                  <Bargaining group={group} />
                </div>
              </div>
            </div>
          )}
          <div className='col mb-4'>
            <div className='card'>
              <div className='card-header'>Chat History</div>
              <div
                className='card-body'
                style={{ height: '83vh', overflow: 'auto' }}>
                <ChatHistory group={group} offer={selectedOffer} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(StudentDebrief);
