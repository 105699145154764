import React from 'react';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';
import { setupSocket, enterBackend, socketOn } from '../../helpers/sockets';

import Header from '../../components/Header';
import DancefloorChart from '../../components/DancefloorChart';
import { DebriefMenu } from './debrief_menu';

class DebriefDancefloor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: 0,
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      // dispatch(gameActions.fetchGames(match.params.id))
      fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
        .then((res) => res.json())
        .then((data) => {
          setupSocket(data.io_domain, () => {
            enterBackend({ game_id: match.params.id }, (socketGameData) => {
              console.log({ socketGameData });
            });
            // Sockets!
            socketOn('offer', (m) => {
              dispatch(gameActions.fetchGames(match.params.id));
            }); // socketOn('nodeal', (m) => console.log(m) );
          });
        });
    }
  }

  render() {
    const { group } = this.state;
    const { game } = this.props;

    if (!game.data) return null;

    const maxDanceLength = Math.max(
      ...game.data.groups.map((s) => s.items.length)
    );
    console.log({ maxDanceLength });
    let dyads =
      group && game.data.groups[group - 1]
        ? [game.data.groups[group - 1]]
        : game.data.groups;

    const dances = game.data
      ? dyads
          .filter((dyad) => dyad.players.length === game.data.ppg)
          .map((dyad, di) => {
            const energetics = dyad.players.find((p) => p.rindex === 1);
            const dance = dyad.items.map((i, ii) => {
              return {
                pair_index: di,
                x: ii + 1,
                y: i.amount,
                status:
                  dyad.status === 'nodeal'
                    ? ' No Deal'
                    : dyad.status === 'deal'
                    ? 'Deal'
                    : 'In Progress',
                batna: energetics.rsub,
                author: i.author_role === 1 ? 'Energetics' : 'Generex',
                batna: energetics.rsub === 0 ? 'Low ($170M)' : 'High ($225M)',
              };
            });

            if (dyad.status === 'deal')
              return {
                name: `${dyad.players[0].email} vs ${dyad.players[1].email}`,
                color: '#28a745',
                data: dance,
              };

            if (dyad.status === 'nodeal')
              return {
                name: `${dyad.players[0].email} vs ${dyad.players[1].email}`,
                color: '#fd5846',
                data: dance,
              };

            return {
              name: `${dyad.players[0].email} vs ${dyad.players[1].email}`,
              color: 'rgba(53, 119, 242)',
              data: dance,
            };
          })
      : [];

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Dancefloor
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3 pb-5'>
          <DancefloorChart
            dances={dances}
            minRange={maxDanceLength}
            popup={true}
          />

          <div
            className='mt-3 text-center'
            style={{
              color: '#333',
              fontSize: '12px',
              fontWeight: 600,
              fontFamily:
                '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
            }}>
            <span
              className='badge badge-primary'
              style={{
                height: '14px',
                width: '14px',
                lineHeight: '14px',
                borderRadius: '50%',
              }}>
              &nbsp;
            </span>{' '}
            In Progress
            <span
              className='badge ml-4'
              style={{
                height: '14px',
                width: '14px',
                lineHeight: '14px',
                borderRadius: '50%',
                backgroundColor: '#28a745',
              }}>
              &nbsp;
            </span>{' '}
            Deal
            <span
              className='badge ml-4'
              style={{
                height: '14px',
                width: '14px',
                lineHeight: '14px',
                borderRadius: '50%',
                backgroundColor: '#fd5846',
              }}>
              &nbsp;
            </span>{' '}
            No Deal
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(DebriefDancefloor);
