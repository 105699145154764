import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import { GameForm } from './form';
import { GameSetup } from './setup';
import { GamePlayers } from './players';
import { GameDebrief } from './debrief';
import GameDancefloor from './dancefloor';
import GameBatna from './batna';
import GameBargaining from './bargaining';
import GameFirst from './first';

// import { GameOffers } from './offers';
// import { GameTypes } from './types';
// import { GamePareto } from './pareto';

import { GameLeaderboard } from './leaderboard';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
    };

    // Bindings
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    this.props.dispatch(gameActions.fetchGames(id));
  }

  render() {
    const { game, match } = this.props;
    // const { item } = this.state;

    const SetupComponent =
      game && game.data && game.data.access_type === 'lti-hbp'
        ? GameSetup
        : GameForm;

    return (
      <div>
        {game && game.data && (
          <div>
            <Route exact path={match.path} component={SetupComponent} />
            <Route
              exact
              path={match.path + '/players'}
              component={GamePlayers}
            />
            {/* <Route path={match.path + '/players'} render={ props => <GamePlayers {...props} item={item} /> } /> */}
            <Route
              exact
              path={match.path + '/debrief/overview'}
              component={GameDebrief}
            />
            <Route
              exact
              path={match.path + '/debrief/dancefloor'}
              component={GameDancefloor}
            />
            <Route
              exact
              path={match.path + '/debrief/batna'}
              component={GameBatna}
            />
            <Route
              exact
              path={match.path + '/debrief/bargaining'}
              component={GameBargaining}
            />
            <Route
              exact
              path={match.path + '/debrief/first'}
              component={GameFirst}
            />
            <Route
              exact
              path={match.path + '/debrief/leaderboard'}
              component={GameLeaderboard}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameItem };
